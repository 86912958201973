<template>
  <i :class="`iconfont icon-${icon}`" @click="handleClick"></i>
</template>

<script>
const iconMap = {
  arrowDown: "jiantou-copy-copy",
  arrowRight: "jiantou",
  pdf: "pdf1",
};
export default {
  props: {
    type: String,
    link: String,
  },
  computed: {
    icon() {
      const { type } = this;
      return iconMap[type];
    },
  },
  methods: {
    handleClick() {
      const { link, $q } = this;
      if (link.startsWith("#")) {
        var target_roll_height = $q(link).offset().top;
        $q("html,body").animate({ scrollTop: target_roll_height }, 200);
      } else if (link.startsWith('/')) {
        this.$router.push(link)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("//at.alicdn.com/t/c/font_4133489_6nz4w1vjm0c.css");

.iconfont {
  opacity: 0.8;
  cursor: pointer;
}
</style>