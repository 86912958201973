// 节流
export function throttle(fn, delay = 100) {
  let valid = true;
  return function () {
    if (valid) { //如果阀门已经打开，就继续往下
      setTimeout(() => {
        fn.apply(this, arguments);//定时器结束后执行
        valid = true;//执行完成后打开阀门
      }, delay)
      valid = false;//关闭阀门
    }
  }
};

// 防抖
export function debounce(fn, delay = 100) {
  let timer = null;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay)
  }
}
