export const menu = [
    {
        name: "首页",
        link: "/",
        children: [],
    },
    {
        name: "空间装饰",
        link: "/space-decoration",
        children: [],
    },
    {
        name: "意大利黏土",
        link: "/italian-clay",
        children: [],
    },
    {
        name: "石灰涂料",
        link: "/lime-paint",
        children: [],
    },
    {
        name: "生物涂料",
        link: "/bio-paint",
        children: [],
    },
    {
        name: "色彩",
        link: "/color",
        children: [],
    },
    {
        name: "产品",
        link: "/production",
        children: [],
    },
    {
        name: "案例",
        link: "/case",
        children: [],
    },
    {
        name: "联系我们",
        link: "/link-us",
        children: [],
    },
    {
        name: "常见问题",
        link: "/f-a-q",
        children: [],
    },
]