import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home')
  }, {
    path: '/space-decoration',
    name: 'SpaceDecoration',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Decoration')
  }, {
    path: '/italian-clay',
    name: 'ItalianClay',
    component: () => import(/* webpackChunkName: "ItalianClay" */ '@/views/Clay')
  }, {
    path: '/lime-paint',
    name: 'LimePaint',
    component: () => import(/* webpackChunkName: "LimePaint" */ '@/views/Lime')
  }, {
    path: '/bio-paint',
    name: 'BioPaint',
    component: () => import(/* webpackChunkName: "BioPaint" */ '@/views/BioPaint')
  }, {
    path: '/color',
    name: 'Color',
    component: () => import(/* webpackChunkName: "Color" */ '@/views/Color')
  }, {
    path: '/production',
    name: 'Production',
    component: () => import(/* webpackChunkName: "Production" */ '@/views/Production')
  }, {
    path: '/case',
    name: 'Case',
    component: () => import(/* webpackChunkName: "Case" */ '@/views/Case')
  }, {
    path: '/link-us',
    name: 'LinkUs',
    component: () => import(/* webpackChunkName: "LinkUs" */ '@/views/LinkUs')
  }, {
    path: '/f-a-q',
    name: 'Question',
    component: () => import(/* webpackChunkName: "Question" */ '@/views/Question')
  }, {
    path: '/address',
    name: 'Address',
    component: () => import(/* webpackChunkName: "Address" */ '@/views/Address')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router
