<template>
  <footer class="page_footer">
    <div class="footer_wrapper">
      <div class="container">
        <el-row :gutter="60">
          <el-col class="f-column" :md="6" :sm="24" :xs="24">
            <img src="@/assets/img/footer_logo.png" class="footer_logo" alt="" />
          </el-col>
          <el-col class="f-column" :md="6" :sm="24" :xs="24">
            <div class="widget">
              <div class="widget_title">关于品牌</div>
              <ul class="menu">
                <li class="menu_item textwidget" v-for="(nav, i) in menu" :key="i">
                  <router-link :to="nav.link">{{ nav.name }}</router-link>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col class="f-column" :md="6" :sm="24" :xs="24">
            <div class="widget widget_text">
              <div class="widget_title">关于服务</div>
              <ul class="menu">
                <li class="menu_item textwidget">
                  <router-link to="/f-a-q">常见问题</router-link>
                </li>
                <li class="menu_item textwidget">
                  <router-link to="/address">地图地址</router-link>
                </li>
                <li class="menu_item textwidget">
                  <router-link to="/link-us">联系我们</router-link>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col class="f-column" :md="6" :sm="24" :xs="24">
            <div class="widget widget_text">
              <div class="widget_title">关于我们</div>
              <div class="textwidget">
                <el-popover placement="top" trigger="hover">
                  <img src="@/assets/img/qr_code.png" style="width: 14em; height: 14em" />
                  <a href="javascript:void(0);" class="out_link" slot="reference">
                    <img src="@/assets/img/footer/ic_weixin_web.png" width="32" alt="" />
                  </a>
                </el-popover>

                <a target="_blank"
                  href="https://www.xiaohongshu.com/user/profile/64546c3e0000000014012550?xhsshare=CopyLink&appuid=63ae95a30000000028019891&apptime=1686797669"
                  class="out_link">
                  <img src="@/assets/img/footer/ic_xiaohongshu_web.png" width="32" alt="" />
                </a>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="copy_right">
      <div class="container">
        <el-row>
          <el-col :xs="24">
            <div class="elm" style="height: 100%">
              <p>
                <a href="https://beian.miit.gov.cn/">
                  Copyright © 2022-2023 鲁ICP备19019826号-6 版权所有：青岛瑞加漆业有限公司
                  鲁ICP备19019826号-6
                </a>
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </footer>
</template>

<script>
import { menu } from "@/util/data";
export default {
  name: "PageFooter",
  data() {
    return {
      menu,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer_wrapper {
  padding: 60px 0;
  background-color: #616b70;
  box-sizing: border-box;



  ::selection {
    color: #fff;
    background-color: transparent;
  }

  .widget_text {
    margin-bottom: 50px;
  }

  .widget {
    .widget_title {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
      text-transform: None;
      margin-bottom: 20px;
      color: #fff;
    }

    .menu_item {
      padding-bottom: 7px;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-transform: None;

      a {
        border-bottom-color: transparent;
        border-bottom-style: solid;
        border-bottom-width: 1px;
      }

      &:hover a {
        color: #f2f2f2;
        border-bottom-color: #f2f2f2;
        border-bottom-style: solid;
        border-bottom-width: 1px;
      }
    }

    .textwidget {
      color: #9fb0b7;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-transform: None;

      .out_link {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .f-column {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: 992px) {
  .footer_logo {
    width: 100%;
  }
}

.copy_right {
  background-color: #303030;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  overflow: hidden;
  box-sizing: border-box;
  overflow: hidden;

  p {
    font-size: 12px !important;
  }
}
</style>