<template>
  <header class="header" :class="{ bgGrey }">
    <router-link class="logo" to="/">
      <img src="../assets/img/logo.png" alt="" class="logo" />
    </router-link>

    <ul class="menu">
      <li class="nav-menu-item" v-for="item in menu" :key="item.name">
        <router-link :to="item.link">
          {{ item.name }}
          <span class="icon" v-if="item.children.length > 0">
            <Icon type="arrowDown" />
          </span>
        </router-link>
      </li>
    </ul>
  </header>
</template>

<script>
import Icon from "./Icon.vue";
import { throttle } from "@/util";
import { menu } from "@/util/data";
export default {
  name: "Header",
  components: { Icon },
  data() {
    return {
      menu,
      clinetHeight: 0,
      scrollTop: 0,
    };
  },
  computed: {
    bgGrey() {
      return this.scrollTop >= this.clinetHeight || this.$route.path === '/address'
    }
  },
  mounted() {
    this.getWindowHeight();
    window.addEventListener("resize", this.getWindowHeight);
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    getWindowHeight() {
      this.clinetHeight = window.innerHeight;
    },
    handleScroll: throttle(function () {
      const top = document.documentElement.scrollTop;
      this.scrollTop = top;
    }),
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowHeight);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

.header {
  // background: rgb(97, 107, 112);
  background-color: transparent;
  height: 100px;
  padding: 0 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  @include betweenCenter;

  &.bgGrey {
    background-color: #616b70;
  }

  .logo {
    width: 327.27px;
    height: 80px;
  }

  .menu {
    display: flex;

    .nav-menu-item {
      padding: 0px 14px;
      position: relative;

      &:hover .sub-menu {
        opacity: 1;
      }

      a {
        color: rgba(255, 255, 255, 0.75);
        font-size: 12px;
        padding: 6px 0;
        display: inline-block;
        border-bottom: 1px solid transparent;
        transition: border 0.3s ease-out;
        letter-spacing: 1px;
        position: relative;

        &.router-link-exact-active {
          color: #fff;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }

        &:hover {
          color: #fff;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
      }

      .icon {
        margin-top: -5px;
        display: inline-block;
        transform: scale(0.6) translateY(5px);
        width: 14px;
      }

      .sub-menu {
        position: absolute;
        left: 0;
        right: 0;
        top: 63px;
        background-color: #9fb0b7;
        z-index: 999;
        width: 168px;
        opacity: 0;
        transition: opacity 0.75s;

        &>.sub-nav-link {
          white-space: nowrap;
          padding: 5px 20px;

          a {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>