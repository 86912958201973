import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
//导入css
import './assets/css/font-face.css';
import './assets/css/reset.css';
import './assets/css/global.scss';
import 'animate.css';

const bootstrap = require('bootstrap');


import $ from 'jquery';


Vue.config.productionTip = false

Vue.prototype.$bootstrap = bootstrap;
Vue.prototype.$q = $;

Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
