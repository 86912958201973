<template>
  <div id="app">
    <Header class="d-none d-xl-flex" />
    <PhoneHeader class="d-xl-none" />
    <div style="height: 60px;" class="d-xl-none" />
    <router-view />
    <Footer />
    <el-backtop>
      <img class="pc_up_top" src="@/assets/img/up_to_top_03.svg" />
    </el-backtop>
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import PhoneHeader from "./components/PhoneHeader.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    PhoneHeader
  },
};
</script>
<style lang="scss" scoped>
@keyframes jump {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.el-backtop {
  background-color: transparent;

  &:hover {
    background-color: transparent;

    .pc_up_top {
      animation: jump 800ms;
    }
  }
}
</style>
