<template>
    <div class="phone_header">
        <div class="header_inner d-flex justify-content-between align-items-center">
            <router-link to="/">
                <img src="@/assets/img/mobile_logo.png" class="mobile_logo" />
            </router-link>
            <ul class="menu_toggle" :class="{ active }" @click="active = !active">
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
        <div class="mobile_menu" :class="{ shown: active }" @transitionend="handleTransitionEnd" ref="menuWrap">
            <div class="mask" @click="active = false" />
            <div class="menu_content" ref="menu">
                <ul class="container menu_list">
                    <li class="mnav_menu_item" v-for="(it, i) in menu" :key="i" @click="active = false">
                        <router-link :to="it.link">{{ it.name }}</router-link>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
import { menu } from '@/util/data'
export default {
    name: 'PhoneHeader',
    data() {
        return {
            active: false,
            menu,
            menuHeight: 0
        }
    },
    watch: {
        active(val) {
            if (val) {
                this.$refs.menuWrap.style.display = 'block';
                this.$refs.menu.clientHeight;
                this.$refs.menu.style.height = this.menuHeight + 'px';
            } else {
                this.$refs.menu.style.height = 0 + 'px';
            }
        }
    },
    mounted() {
        this.menuHeight = this.$refs.menu.clientHeight;
        this.$refs.menu.style.height = 0 + 'px';
        this.$refs.menuWrap.style.display = 'none'
    },
    methods: {
        handleTransitionEnd() {
            if (!this.active) {
                this.$refs.menuWrap.style.display = 'none';
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.phone_header {
    position: fixed;
    left: 0;
    right: 0;
    background-color: #616b70;
    height: 60px;
    padding-left: 60px;
    padding-right: 60px;
    z-index: 9999;

    .header_inner {
        height: 100%;
    }

    .mobile_logo {
        width: 163px;
        height: 40px;
    }

    .menu_toggle {
        cursor: pointer;

        li {
            height: 3px;
            border-radius: 3px;
            background-color: #fff;
            margin-top: 4px;
            width: 20px;
            transition: all .5s;

            &:first-child {
                margin-top: 0;
            }
        }

        &.active li {
            &:first-child {
                transform: rotate(45deg) translateX(5px) translateY(5px);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:last-child {
                transform: rotate(-45deg) translateX(5px) translateY(-5px);
            }
        }
    }

    .mobile_menu {
        position: fixed;
        top: 60px;
        left: 0;
        width: 100vw;
        height: calc(100vh - 60px);
        border-top: 1px solid #fff;

        .mask {
            background-color: rgba(0, 0, 0);
            opacity: 0;
            transition: opacity .5s;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }

        &.shown .mask {
            opacity: 0.7;
        }

        .menu_content {
            background-color: rgba(97, 107, 112, 0.37);
            overflow: hidden;
            transition: all .5s;
            position: relative;
            z-index: 1;
        }

        .menu_list {
            padding-top: 10px;
            padding-bottom: 30px;
        }

        .mnav_menu_item {
            border-bottom: 1px solid #b3b3b366;
            font-family: Open Sans;
            font-size: 15px;
            font-weight: 400;
            text-transform: None;
            color: #ffffff;
            padding: 10px 0;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

@media screen and (max-width:576px) {
    .phone_header {
        padding: 0 20px;
    }
}
</style>